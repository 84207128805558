import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CommonModule } from '../common/common.module';
import { AppMaterialModule } from '../services/app-material/app-material.module';
import { AlertEmailDialog } from './alert-email-dialog/alert-email-dialog.component';
import { AlertTextDialog } from './alert-text-dialog/alert-text-dialog.component';
import { ApplyModelDialog } from './apply-model-dialog/apply-model-dialog.component';
import { CanSignalSelectionDialog } from './can-signal-selection-dialog/can-signal-selection-dialog.component';
import { ConfirmDeleteDialog } from './confirm-delete-dialog/confirm-delete-dialog.component';
import { ConfirmDialog } from './confirm-dialog/confirm-dialog.component';
import { CreateDashboardDialog } from './create-dashboard-dialog/create-dashboard-dialog.component';
import { CreateDeviceDialog } from './create-device-dialog/create-device-dialog.component';
import { DateRangeDialog } from './date-range-dialog/date-range-dialog.component';
import { InfoDialog } from './info-dialog/info-dialog.component';
import { DeviceDashboardDialog } from './device-dashboard-dialog/device-dashboard-dialog.component';
import { EditModuleDialog } from './edit-module-dialog/edit-module-dialog.component';
import { FeedbackDialog } from './feedback/feedback.component';
import { IdentifiedTrackingDialog } from './identified-tracking-dialog/identified-tracking-dialog.component';
import { ImportJsonDialog } from './import-json-dialog/import-json-dialog.component';
import { NewPasswordDialog } from './new-password-dialog/new-password-dialog.component';
import { SelectDevicesDialog } from './select-devices-dialog/select-devices-dialog.component';
import { SelectDialog } from './select-dialog/select-dialog.component';
import { SelectOrganizationDialog } from './select-organization-dialog/select-organization-dialog.component';
import { SelectUserDialog } from './select-user-dialog/select-user-dialog.component';
import { SimpleInputDialog } from './simple-input-dialog/simple-input-dialog.component';
import { WarningDialog } from './warning-dialog/warning-dialog.component';

@NgModule({
    declarations: [
        AlertEmailDialog,
        AlertTextDialog,
        ApplyModelDialog,
        CanSignalSelectionDialog,
        ConfirmDeleteDialog,
        ConfirmDialog,
        CreateDashboardDialog,
        CreateDeviceDialog,
        DateRangeDialog,
        DeviceDashboardDialog,
        EditModuleDialog,
        FeedbackDialog,
        IdentifiedTrackingDialog,
        ImportJsonDialog,
        NewPasswordDialog,
        SelectOrganizationDialog,
        SelectDevicesDialog,
        SelectDialog,
        SelectUserDialog,
        SimpleInputDialog,
        WarningDialog,
        InfoDialog,
    ],
    imports: [
        AppMaterialModule,
        RouterModule,
        ScrollingModule,
        NgxMatSelectSearchModule,
        CommonModule,
    ],
    exports: [
        AlertEmailDialog,
        AlertTextDialog,
        ApplyModelDialog,
        ConfirmDeleteDialog,
        ConfirmDialog,
        CreateDashboardDialog,
        CreateDeviceDialog,
        DateRangeDialog,
        DeviceDashboardDialog,
        EditModuleDialog,
        FeedbackDialog,
        IdentifiedTrackingDialog,
        ImportJsonDialog,
        NewPasswordDialog,
        SelectOrganizationDialog,
        SelectDevicesDialog,
        SelectDialog,
        SelectUserDialog,
        CanSignalSelectionDialog,
        SimpleInputDialog,
        WarningDialog,
        InfoDialog,
    ],
})
export class DialogModule {}
