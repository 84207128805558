import { environment } from 'src/environments/environment';

export type NavSection = {
    id: string;
    title: string;
    paths: string[];
};

export const allSections: NavSection[] = [
    {
        id: 'features',
        title: 'nav.title.features',
        paths: [
            '/dashboards',
            '/data-files',
            '/reports',
            '/alerts',
            '/geo-fences',
        ],
    },
    {
        id: 'customization',
        title: 'nav.title.customization',
        paths: ['/models', '/widgets'],
    },
    {
        id: 'management',
        title: 'nav.title.management',
        paths: ['/devices', '/users', '/organizations'],
    },
    {
        id: 'extras',
        title: 'nav.title.extras',
        paths: [
            '/page-views',
            '/devices/connections',
            '/dashboards/overview',
            '/device-simulators',
        ],
    },
    {
        id: 'help',
        title: 'nav.title.help',
        paths: [
            environment.docsUrl,
            '/about',
            '/announcements',
            '/about/roadmap',
        ],
    },
];
